//Home.js

import { Link, NavLink } from "react-router-dom";
import PdfLink from "../components/PdfLink";

import Slides from "../components/Slides";

import Slides_tg from "../components/Slides_tg";

export default function Home() {
  return (
    <div className="w-full min-h-full flex-col justify-center align-center items-center md:mx-3 my-5 ">
      <div className="pb-16">
        <Ad />
      </div>
      <div>
        <Tg className="pb-8" />
      </div>
    </div>
  );
}

function Ad() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto lg:gap-10 lg:justify-around">
      <div className="flex  text-center gap-3">
        <div>
          <PdfLink title="This week's sales" Pdf="/flyer.pdf" />
          <p>Friday, November 8 - Thursday, November 14</p>
        </div>
      </div>
      <div className=" flex">
        <Slides />
      </div>
    </div>
  );
}

function Tg1() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto lg:gap-10 lg:justify-around">
      <div className=" flex">
        <Slides_tg />
      </div>
      <div className="flex  text-center gap-3">
        <div>
          <h1 className="pb-2 font-Podkova">Thanksgiving Packages</h1>
          <h3>Call to place your order!</h3>
          <div className="flex flex-row justify-between py-3">
            <p>Meat Department</p>
            <a href="tel:5164898450">(516) 489-8450</a>
          </div>
          <div className="flex flex-row justify-between py-3">
            <p>Pat's Farms Main Line</p>
            <a href="tel:5164815525">(516) 481-5525</a>
          </div>
          <div className="flex flex-row justify-between py-3">
            <p className=" text-gray-500 italic text-sm">
              **After 1pm please call Pat's Farms main line
            </p>
            <a href="tel:5164815525" className=" text-gray-500 italic text-sm">
              (516) 481-5525
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
function Tg() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto lg:gap-10 lg:justify-around">
      <div className=" flex">
        <Slides_tg />
      </div>
      <div className="flex  text-center gap-3 ">
        <div>
          <h1 className="pb-2 font-Podkova">Thanksgiving Packages</h1>
          <h3 className="mb-4">Call to place your order!</h3>

          <a href="tel:5164815525" className=" py-3 text-2xl font-Montserrat">
            (516) 481-5525
          </a>

          <div className="h-48 bg-white"></div>
        </div>
      </div>
    </div>
  );
}
